import ModeClassic from './Mode_classic.svg'
import ModeFav from './Mode_fav.svg'
import ModeLanguage from './Mode_language.svg'
import ModeMath from './Mode_math.svg'
import ModeQuick from './Mode_quick.svg'
import ModeStrength from './Mode_strength.svg'

// There Icons are svgs, we can control the size using css or styled component from the parent
const modeMap: Record<WorkoutModeTypes, typeof ModeClassic> = {
  classic: ModeClassic,
  math: ModeMath,
  language: ModeLanguage,
  favorites: ModeFav,
  strengthen: ModeStrength,
  quick: ModeQuick,
}

export const WorkoutModeIcon = ({ mode }: { mode: WorkoutModeTypes }) => {
  const Icon = modeMap[mode] || modeMap.classic
  return <Icon title={mode} />
}
